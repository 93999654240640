import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import NewMsgPanel from './newMsgPanel';

const Posts = () => {
  const [posts, setPosts] = useState([]);

  async function getPosts() {
    const resp = await fetch(
      "https://tins-cloudflare-assignment-worker.tinhangchui.workers.dev/posts"
    );
    const postsResp = await resp.json();
    setPosts(postsResp);
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <NewMsgPanel getPosts={getPosts}/>
      {posts.map((post) => 
        <div key={post.content}>
          <Modal.Dialog size="lg">
              <Modal.Header closeButton={false}>
                  <strong className="me-auto">{post.title}</strong>
                  <small>by {post.username}</small>
              </Modal.Header>
              <Modal.Body align='left'>
                  {post.content}
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={()=> alert("Feature coming soon")}>Reply</Button>
              </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </>
  );
};

export default Posts;