import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Posts from './components/posts';
import TinsNavbar from './components/navbar'

import './App.css';

const App = () => {
  useEffect(() => {
    document.title = "Tins Website";  
  }, []);
  
  return (
  <>
  <style>
    {`
      .main {
        background-image: linear-gradient(lightgrey, white);
      }
    `}
  </style>
  <div className="main">
    <Container className="p-4" align='center'>
        <TinsNavbar />
        <Posts />
    </Container>
  </div>
  </>
  );
};

export default App;