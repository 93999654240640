import React, { useEffect, useState } from "react";
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

const NewMsgPanel = (prop) => {
    const [username, setUserName] = useState("");
    const [title, setTitle] = useState("");
    const [msgContent, setMsgContent] = useState("");

    function sendPost(event, username, title, msgContent) {
        if (username != "" && title != "" && msgContent != "")
        {
            let postData = {'title': title, 'username': username, 'content': msgContent};
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify(postData)
                };
            fetch(
                "https://tins-cloudflare-assignment-worker.tinhangchui.workers.dev/posts",
                requestOptions)
                .then((result) => prop.getPosts());
        }
    }

    return (
        <>
            <style>
                {`
                    .fixed-textarea {
                        resize: none;
                        height: 200px;
                    }
                `}
            </style>
            <div>
                <p className="m-3">Feel free to write down something here!</p>
                <InputGroup className="w-50 m-3">
                    <InputGroup.Text>Username:</InputGroup.Text>
                    <FormControl
                    placeholder="Type your username here..."
                    aria-label="Username" 
                    id="usernameInput"
                    onChange={(event) => setUserName(event.target.value)}
                    />
                </InputGroup>

                <InputGroup className='w-50 m-3'>
                    <InputGroup.Text>Title:</InputGroup.Text>
                    <FormControl
                    placeholder="Title"
                    id="titleInput"
                    onChange={(event) => setTitle(event.target.value)}
                    />
                </InputGroup>
                
                <InputGroup className='w-50 m-3'>
                    <InputGroup.Text id="contentInput">Content:</InputGroup.Text>
                    <FormControl 
                    as="textarea" 
                    className="fixed-textarea"
                    placeholder="Write something here..."
                    aria-label="With textarea" 
                    onChange={(event) => setMsgContent(event.target.value)}
                    />
                </InputGroup>

                <Button variant="primary" onClick={(event) =>
                     sendPost(event, username, title, msgContent)}>Post</Button>
            </div>
        </>
    )
};

export default NewMsgPanel;